html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* On mobile screens, stop horizontal scrolling */
body,
html {
  @media (max-width: 960px) {
    overflow-x: hidden;
  }
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
  color: #0B093B;
}

a:hover {
  color: #7620FF;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset;
  /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}

body {
  background: #12141A;
  color: #0B093B;
  font-family: 'Inter', 'Khula', sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}

.container {
  width: 100%;
  /* max-width: 1220px; */
  margin: 0 auto;
  padding: 0 10vw;
}

.containerSm {
  width: 100%;
  /* max-width: 1220px; */
  margin: 0 auto;
  padding: 0 5vw;
}


@media only screen and (max-width: 760px) {
  .container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 560px) {
  .container {
    padding: 0 20px;
  }
}

.regular {
  font-weight: 400 !important;
}

.semiBold {
  font-weight: 600 !important;
}

.extraBold {
  font-weight: 800 !important;
}

/* HELPERS */
.textCenter {
  text-align: center
}

.animate {
  transition: 0.3s ease;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.x100 {
  width: 100%;
}

.active {
  color: var(--white, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* GM/Heading/Heading 6 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 24px */
}

.tag {
  padding: 5px 10px;
  margin: 10px 0;
}

.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}

/* FLEXBOX */
.flex {
  display: flex;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexDayCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexNullCenter {
  display: flex;
  align-items: center;
}

.flexColumn {
  flex-direction: column;
}

.flexChangerStart {
  align-items: start;
}

.flexChangerEnd {
  align-items: end;
}

.flexChangerSpaceBetween {
  justify-content: space-between;
}

.flexChangerCenter {
  justify-content: center;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.videoPlaceholder {
  width: 60%;
  padding-top: 45%;
  background-color: #000;
  position: relative;
}

.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}

.font12 {
  font-size: 0.75rem;
}

.font13 {
  font-size: 0.813rem;
}

.font14 {
  font-size: 0.875rem;
}

.font15 {
  font-size: 0.938rem;
}

.font18 {
  font-size: 1.125rem;
}

.font20 {
  font-size: 1.25rem;
}

.font25 {
  font-size: 1.563rem;
}

.font30 {
  font-size: 1.875rem;
}

.font40 {
  font-size: 2.5rem;
}

.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }

  .font12 {
    font-size: 0.688rem;
  }

  .font13 {
    font-size: 0.75rem;
  }

  .font14 {
    font-size: 0.813rem;
  }

  .font15 {
    font-size: 0.813rem;
  }

  .font18 {
    font-size: 0.938rem;
  }

  .font20 {
    font-size: 1.125rem;
  }

  .font25 {
    font-size: 1.25rem;
  }

  .font30 {
    font-size: 1.5rem;
  }

  .font40 {
    font-size: 1.875rem;
  }

  .font60 {
    font-size: 2.8rem;
  }
}

/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}

.radius6 {
  border-radius: 0.6rem;
}

/* COLORS */
.darkColor {
  color: #0B093B;
}

.purpleColor {
  color: #7620FF;
}

.orangeColor {
  color: #F2B300;
}

.lightColor {
  color: #F5F5F5;
}

.coralColor {
  color: #C4FFFF;
}

.greenColor {
  color: #49CB86;
}

.greyColor {
  color: #707070;
}

.whiteColor {
  color: #fff;
}

/* BACKGROUNDS */
.darkBg {
  background-color: #12141A;
}

.purpleBg {
  background-color: #7620FF;
}

.orangeBg {
  background-color: #F2B300;
}

.lightBg {
  background-color: #F5F5F5;
}

.coralBg {
  background-color: #C4FFFF;
}

.greenBg {
  background-color: #49CB86;
}

.greyBg {
  background-color: #707070;
}

.whiteBg {
  background-color: #fff;
}

@font-face {
  font-family: 'valizas';
  src: url(../assets/fonts/ValizasVariable-Regular.ttf);
}

@font-face {
  font-family: 'inter';
  src: url(../assets/fonts/InterVariable-Regular.ttf);
}

@font-face {
  font-family: 'aeonik';
  src: url(../assets/fonts/Aeonik-Regular.ttf);
}


.greymattersHeaderName {
  color: var(--white, #FFF);
  font-family: Valizas;
  font-size: 23.498px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
}

.navItem {
  color: var(--white, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* GM/Heading/Heading 6 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 24px */
}

.video-container {
  height: 1200;
  width: 100%;
  position: relative;
  background: #414653;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  opacity: 0.4;
  mix-blend-mode: soft-light;
}

.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
  color: #dc0000;
  padding: 10px;
}

.backgroundLighter {
  background: var(--lite-slate, #1D1F27);
}

.backgroundLavender {
  background: #A6BCFF;
}



/* About Us*/